/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019纷析云（杭州）科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : 字典</li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年08月02日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {heyuiConfig} from "heyui.ext";

heyuiConfig.initDict({
  accountingStandards: {
    SMALL_BUSINESS: '小企业会计准则',
    BUSINESS: '企业会计准则',
    BUSINESS20191: '企业会计准则(2019年未执行新金融准则、新收入准则和新租凭准则)',
    BUSINESS20192: '企业会计准则(2019年已执行新金融准则、新收入准则和新租赁准则)',
    NONGOVERNMENTAL: '民间非营利组织会计制度',
    FARMER: '农民专业合作社财务会计制度'
  },
  accountingStandardsPrint: {'SMALL_BUSINESS': "小企", 'BUSINESS': "企业"},
  reportPrint: {'zcfz': "01表", 'lr': "02表", 'xjll': "03表"},
  defaultRadios: [{key: true, title: '是'}, {key: false, title: '否'}],
  statusRadios: [{key: true, title: '正常'}, {key: false, title: '不可用'}],
  enableRadios: [{key: true, title: '启用'}, {key: false, title: '不启用'}],
  needRadios: [{key: true, title: '需要'}, {key: false, title: '不需要'}],
  reportTemplateType: {0: "普通报表", 1: "资产报表"},
  reportTemplateItemType: {0: "资产", 1: "负债", 2: "所有者权益"},
  accessRules: ["发生额", "借方发生额", "贷方发生额", "余额", "期初余额", "借方余额", "贷方余额"],
  biAccessRules: ["借方发生额", "贷方发生额", "余额", "借方余额", "贷方余额"],
  zcfzAccessRules: ["余额", "借方余额", "贷方余额", "末级借方余额", "末级贷方余额", "辅助核算借方余额", "辅助核算贷方余额"],
  xjllAccessRules: ["余额", "借方余额", "贷方余额", "期初余额", "发生额", "借方发生额", "贷方发生额"],
  lrAccessRules: ["发生额", "贷方发生额"],
  operation: {'+': " +", '-': "-"},
  reportTemplateItemSources: {0: "表外公式", 1: "表内公式"},
  vatRadios: {'SMALL_SCALE': "小规模纳税人", 'COMMONLY': "一般纳税人"},
  roles: {'Manager': "账套管理员", 'Director': "主管", 'Making': "制单人", 'Cashier': "出纳", 'View': "查看"},
  voucherTemplateType: {0: "日常支出", 1: "采购", 2: "销售", 3: "工资", 4: "税金", 5: "折旧和摊销"},
  industry: {
    "0": "IT·通信·电子·互联网",
    "1": "金融业",
    "2": "房地产·建筑业",
    "3": "商业服务",
    "4": "贸易·批发·零售·租赁业",
    "5": "文体教育·工艺美术",
    "6": "生产·加工·制造",
    "7": "交通·运输·物流·仓储",
    "8": "服务业",
    "9": "文化·传媒·娱乐·体育",
    "10": "能源·矿产·环保",
    "11": "政府·非盈利机构",
    "12": "农·林·牧·渔·其他"
  },
  assetsTemplateType: {
    '固定资产新增': '固定资产新增',
    '原值增加': '原值增加',
    '原值减少': '原值减少',
    '累计折旧变动': '累计折旧变动',
    '固定资产减少': '固定资产减少'
  },
});
