/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023 纷析云（杭州）技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年11月06日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {defineAsyncComponent} from "vue";


export default {
  moduleKey: "Invoice",
  components: [
    {name: 'OutputInvoice', title: '销项发票', component: defineAsyncComponent(() => import('@/views/invoice/OutputInvoice.vue'))},
    {name: 'IncomeInvoice', title: '进项发票', component: defineAsyncComponent(() => import('@/views/invoice/IncomeInvoice.vue'))},
    {name: 'InvoiceBatchDownload', title: '一键取票', component: defineAsyncComponent(() => import('@/views/invoice/InvoiceBatchDownload.vue'))},
  ],
  menu: [{
    title: '发票',
    key: 'Invoice',
    icon: 'fi fi-fp',
    children: [
      {
        title: '销项发票',
        key: 'OutputInvoice',
        auth: 'invoice-output-canview',
      },
      {
        title: '进项发票',
        key: 'IncomeInvoice',
        auth: 'invoice-input-canview',
      },
      {
        title: '一键取票',
        key: 'InvoiceBatchDownload',
        auth: 'invoice-input-canview',
      }
    ]
  }]
}
